import "@babel/polyfill";
import firebase from "firebase/app";
import React, { useEffect, useState } from "react";
import { ApolloProvider } from "react-apollo";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { apollo } from "./api/apollo";
import { saveToken } from "./redux/auth/auth.actions";
import { persistor, store } from "./redux/store";

import { Screens } from "./Screens";

// eslint:disable
require("../styles/index.scss");

const { initClient } = apollo;

const configFirebase = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  storageBucket: process.env.STORAGE_BUCKET,
};
firebase.initializeApp(configFirebase);

const TOKEN =
  // eslint-disable-next-line max-len
  "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI1ZDRUWG5pT1FWWHhWdWlJdlBRWHc2ckx6ZncyIiwiaXNzIjoiZmlyZWJhc2UtYWRtaW5zZGstZjhkYzhAdGVjaG5pcy1jb3VudGluZy5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWY4ZGM4QHRlY2huaXMtY291bnRpbmcuaWFtLmdzZXJ2aWNlYWNjb3VudC5jb20iLCJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcwMjI4ODU1MSwiZXhwIjoxNzAyMjkyMTUxLCJjbGFpbXMiOnsidG9rZW5UeXBlIjoicHJpdmF0ZSIsInRva2VuSWQiOiI1YWM2Yjk5ZjE2YmQyZTIxMWIzZjU0OGZjMzg0ZjMifX0.p0LSeBtZD6aaaR2Ksa0eP0iaiD_A3VEWJRAUmGDAy1Hp-jL2Fw9kGED-KxDi1vjBB6tk0wgpff7UUwlyN13L7TQPN99wE1NeYE-b-77M0c-3Lt2cafjnSpDOcB9HLckMSU743_pJ4Oa4xc1WbwIiwFxk-ORJFo7EGHkFoXirz9oIZQRGVlqM8i_eIsyKaJByfndjHN7xGyXuCgxw43jNx2o1VCcCRe5BZnrgLUkX2YF_Skh1ovfVACNTBRJqFz3iyHgKqOsfyxTtdJuxn5cm67Bw36-gbcwi4PMMNcSF5buECZiydSWKSWbfIr8nIbVMxzVOx2bRt-PCocw8hTQWdw";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    saveToken(TOKEN).then(() => initClient().then(() => setLoading(false)));
  }, []);

  if (loading) {
    return null;
  }

  return (
    <ApolloProvider client={apollo.client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Screens />
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

render(<App />, document.getElementById("root"));
