import * as I18n from "i18n-js";

import { en } from "./en";
import { fr } from "./fr";
import { pl } from "./pl";

export const i18n = I18n;

i18n.locale = "en";
i18n.fallbacks = false;
i18n.translations = { en, fr, pl };

export function getLocale() {
  return i18n.locale;
}

export function setLanguage(language?: string) {
  i18n.locale = language || "en";
}

i18n.pluralization["pl"] = (count: number) => {
  const key = count === 1 ? "1" : count >= 2 && count <= 4 ? "2,3,4" : "many";
  return [key];
};
