import { Zone, NeededOnly, Maybe } from "@technis-counting/shared";
import gql from "graphql-tag";

export type ZoneByIdResult = {
  zoneById: Maybe<NeededOnly<Zone, never, "id" | "name">>;
};

export const zoneById = gql`
  query zoneById($zoneId: Int!) {
    zoneById(zoneId: $zoneId) {
      id
      name
    }
  }
`;
