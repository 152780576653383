import { Action, Dispatch } from "redux";

export enum AppActions {
  SET_LIMIT = "SET_LIMIT",
  RESET_ALL_LIMITS = "RESET_ALL_LIMITS",
}

interface UpdateLimit extends Action<AppActions.SET_LIMIT> {
  limitId: string;
  limit: number;
}

export type AppAction = UpdateLimit | Action<AppActions.RESET_ALL_LIMITS>;

export const setLimit = (limitId: string, limit: number) => (dispatch: Dispatch<AppAction>) => dispatch({ type: AppActions.SET_LIMIT, limitId, limit });
