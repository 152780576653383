export enum Colors {
  WHITE = "#FFFFFF",
  WHITE_5_TRANSPARENCY = "#FFFFFF0D",
  WHITE_10_TRANSPARENCY = "#FFFFFF1A",
  WHITE_20_TRANSPARENCY = "#FFFFFF33",
  WHITE_30_TRANSPARENCY = "#FFFFFF4D",
  WHITE_40_TRANSPARENCY = "#FFFFFF66",
  WHITE_60_TRANSPARENCY = "#FFFFFF99",
  GRAY_100 = "#EFEFEF",
  GRAY_200 = "#DBDBDB",
  GRAY_300 = "#B4B4B4",
  GRAY_400 = "#9E9E9E",
  GRAY_500 = "#8B8B8B",
  GRAY_600 = "#737373",
  GRAY_700 = "#5B5B5B",
  GRAY_800 = "#414141",
  GRAY_900 = "#212121",
  BLACK = "#131313",
  BLACK_98_TRANSPARENCY = "#131313F8",
  BLACK_10_TRANSPARENCY = "#0000001A",
  BLACK_60_TRANSPARENCY = "#13131399",
  PURE_BLACK = "#000000",
  LIGHT_BLACK = "#FF9900B3",

  BLUE = "#005AF0",
  GREEN = "#08C191",
  GREEN_98_TRANSPARENCY = "#07ab80F8",
  GREEN_60_TRANSPARENCY = "#07ab8099",
  PURPLE = "#a537ed",
  PURPLE_98_TRANSPARENCY = "#a537edF8",
  YELLOW = "#ffc433",
  ORANGE = "#ff7a2d",
  DARK_ORANGE = "#bb4602",
  DARK_ORANGE_60_TRANSPARENCY = "#bb460299",
  RED = "#FF302C",
  RED_90_TRANSPARENCY = "#FF302CE6",
  RED_60_TRANSPARENCY = "#FF302C99",
  RED_30_TRANSPARENCY = "#FF302C4D",

  LIGHT_BLUE = "#202846",
  DARK_BLUE = "#061030",
  DARK_BLUE_98_TRANSPARENCY = "#061030F8",
  REGAL_BLUE = "#121C3B",

  DARK_GRAY = "#A7A7A7",
  LIGHT_GRAY = "#F8F8F8",

  PRIMARY = GREEN,
  SECONDARY = DARK_BLUE,
}
