import { Count, ID, Maybe } from "@technis-counting/shared";
import firebase from "firebase/app";
import "firebase/database";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from ".";

const getZoneCountUrl = (periodId: ID, zoneId: ID) => `${process.env.FIREBASE_DATABASE}/counts/${periodId}/zone|${zoneId}`;

type CountFirebase = {
  person?: Maybe<Count>;
};

const DISCONNECT_CHECK_INTERVAL = 59999; // Every 59 seconds

const calculateNumOfAllowedEntries = (limit: number, count: Maybe<Count>) => limit - ((count?.in || 0) - (count?.out || 0));

export const useCount = (token: string, periodId: ID, topZoneId: ID, limit: number, exclusive?: boolean) => {
  const [count, setCount] = useState<Maybe<Count>>(null);

  const isConnectedRef = useRef(false);
  const limitRef = useRef(limit);
  // const canUseFluxControlRef = useRef(false);
  const numOfAllowedEntriesRef = useRef(0);

  useEffect(() => {
    limitRef.current = limit;
    numOfAllowedEntriesRef.current = calculateNumOfAllowedEntries(limit, count);
  }, [limit]);

  const url = getZoneCountUrl(periodId || 0, topZoneId || 0);

  // Firebase listeners
  const saveCount = (nextCount: Maybe<Count>) => {
    if (nextCount) {
      const nextNumOfAllowedEntries = calculateNumOfAllowedEntries(limitRef.current, nextCount);
      numOfAllowedEntriesRef.current = nextNumOfAllowedEntries;
    }
    setCount(nextCount);
  };
  const startListening = () => {
    isConnectedRef.current = true;
    firebase
      .database()
      .ref(url)
      .on(
        "value",
        snap => {
          const val: Maybe<CountFirebase> = snap.val();
          const nextCount = val?.person;
          saveCount(exclusive ? ({ ...nextCount, in: nextCount?.inCurrent, out: nextCount?.outCurrent } as Maybe<Count>) : nextCount);
        },
        (err: never) => {
          console.log(err);
          isConnectedRef.current = false;
        },
      );
  };
  const stopListening = () => {
    firebase
      .database()
      .ref(url)
      .off();
    isConnectedRef.current = false;
  };

  const prevToken = usePrevious(token);
  // Logic to keep firebase alive
  useEffect(() => {
    if (prevToken && isConnectedRef.current) {
      // stopListening();
    }
    startListening();
  }, [token, periodId]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isConnectedRef.current) {
        startListening();
      }
    }, DISCONNECT_CHECK_INTERVAL);
    return () => {
      clearInterval(interval);
      if (isConnectedRef.current) {
        stopListening();
      }
    };
  }, []);

  return {
    count: count || undefined,
    isBiggerThanLimit: numOfAllowedEntriesRef.current <= 0,
    numOfAllowedEntries: numOfAllowedEntriesRef.current,
  };
};
