/* eslint-disable react/no-unescaped-entities */
import { ID } from "@technis-counting/shared";
import { Circle } from "rc-progress";
import React, { FC, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { Textfit } from "react-textfit";
import { CSSTransition } from "react-transition-group";
import { gql } from "./graphql";
import { periodByEvent, PeriodByEventResult } from "./graphql/period.gql";
import { i18n } from "./i18n/i18n";
import { translation } from "./i18n/translation";
import { RootState } from "./redux/store";
import { Colors } from "./utils/colors";
import { useMounted } from "./utils/hooks";
import { useCount } from "./utils/hooks/count";

export type ScreenProps = {
  zoneId: ID;
  eventId: ID;
  lines: number;
  exclusive?: boolean;
  zoneName?: string;
};

const POLL_INTERVAL = 60000;
const FIRST_TS = Date.now();
const VISITORS = "Visiteurs: ";

export const Screen: FC<ScreenProps> = ({ zoneId, eventId, exclusive, lines, zoneName = "" }) => {
  const limitId = `${zoneId}-${exclusive}`;
  const limit = useSelector((state: RootState) => state.app.limits[limitId]) || 0;
  const token = useSelector((state: RootState) => state.auth.token);
  const [name, setName] = useState(zoneName);

  useEffect(() => {
    if (!name) gql.Zone.get(zoneId).then(result => setName(result?.data?.zoneById?.name || ""));
  }, []);

  const periodQuery = useQuery<PeriodByEventResult>(periodByEvent, { variables: { eventId, date: FIRST_TS }, fetchPolicy: "network-only", skip: !token });
  const period = periodQuery.data?.periodByEvent;

  useEffect(() => {
    const interval = setInterval(() => {
      periodQuery.refetch({ date: Date.now() });
    }, POLL_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    periodQuery.refetch({ date: Date.now() });
  }, [token]);

  const { count = { in: 0, out: 0 }, isBiggerThanLimit } = useCount(token || "", period?.id || 0, zoneId, limit, exclusive);

  const inside = count.in - count.out;

  const statusColor = limit && isBiggerThanLimit ? Colors.RED : Colors.GREEN;
  const finalBackgroundColors = [statusColor, Colors.DARK_BLUE];

  return (
    <div className="screen" style={{ maxHeight: `${100 / lines}vh` }}>
      <GradientBackground backgroundColors={finalBackgroundColors} />
      <div className="screen-content">
        <div className="zone-name">
          <div className="title">{exclusive && name && !zoneName ? `${i18n.t(translation.exclusive)} ${name}` : name}</div>
        </div>
        <div className="circle">
          <Circle percent={(inside / limit) * 100} strokeWidth={8} strokeColor={statusColor} trailColor={Colors.BLACK_60_TRANSPARENCY} style={{ width: "100%", height: "100%" }} />
          <div className="inside">
            <Textfit className="text" mode="single" min={1} max={2000} forceSingleModeWidth={false}>
              {limit ? `${inside} / ${limit}` : inside}
            </Textfit>
          </div>
        </div>
        <div className="limit-container">{`${VISITORS} ${count.in}`}</div>
      </div>
    </div>
  );
};

type GradientBackgroundProps = {
  backgroundColors: Colors[];
};

const GradientBackground: FC<GradientBackgroundProps> = ({ backgroundColors }) => {
  const mounted = useMounted();
  const backgroundStyle: React.CSSProperties = { backgroundImage: `radial-gradient(${backgroundColors[0]}, ${backgroundColors[1]}, ${backgroundColors[1]})` };
  return (
    <CSSTransition in={mounted} timeout={0} classNames="gradient-bg" appear unmountOnExit>
      <div className="gradient-bg" style={backgroundStyle} />
    </CSSTransition>
  );
};
