import React, { FunctionComponent } from "react";
import { Screen, ScreenProps } from "./Screen";

setTimeout(() => {
  window.dispatchEvent(new Event("resize"));
}, 1500);

export const Screens: FunctionComponent = () => {
  const screens: Omit<ScreenProps, "lines">[] = [
    {
      zoneId: 1836,
      eventId: 1793,
      zoneName: "Boutique",
    },
    {
      zoneId: 1834,
      eventId: 1793,
      zoneName: "Belvédère",
    },
    {
      zoneId: 1833,
      eventId: 1793,
      zoneName: "Cité du Vin",
    },
  ];

  return (
    <div className="screen-container">
      {screens.map(screen => (
        <Screen
          key={`${screen.zoneId}-${screen.eventId}-${screen.zoneId}`}
          zoneId={screen.zoneId}
          eventId={screen.eventId}
          lines={Math.ceil(screens.length / 2)}
          exclusive={screen.exclusive}
          zoneName={screen.zoneName}
        />
      ))}
    </div>
  );
};
