import { Indexable } from "@technis-counting/shared";
import { AppAction, AppActions } from "./app.actions";

export type AppState = {
  limits: Indexable<number>;
};

export const DEFAULT_STATE: AppState = {
  limits: {},
};

export const reducer = (state: AppState = DEFAULT_STATE, action: AppAction): AppState => {
  switch (action.type) {
    case AppActions.SET_LIMIT:
      return { ...state, limits: { ...state.limits, [action.limitId]: action.limit } };
    case AppActions.RESET_ALL_LIMITS:
      return { ...DEFAULT_STATE };
    default:
      return state;
  }
};
