import { Action } from "redux";
import firebase from "firebase/app";
import "firebase/auth";
import { store } from "../store";

export enum AuthActions {
  AUTH_SAVE_TOKEN = "AUTH_SAVE_TOKEN",
}

export type AuthAction = AuthSaveTokenAction;

interface AuthSaveTokenAction extends Action<AuthActions.AUTH_SAVE_TOKEN> {
  token: string;
}

const login = async (token: string, prevToken?: string) => {
  try {
    if (prevToken) {
      await firebase.auth().signOut();
    }
    await firebase.auth().signInWithCustomToken(token);
  } catch (e) {
    console.log(e);
  }
};

export const saveToken = async (token: string) => {
  const {
    auth: { token: prevToken },
  } = store.getState();
  await login(token, prevToken);
  store.dispatch({ type: AuthActions.AUTH_SAVE_TOKEN, token });
};
