import { ID } from "@technis-counting/shared";
import { ApolloQueryResult, MutationOptions, OperationVariables, QueryOptions, SubscriptionOptions } from "apollo-client";
import { FetchResult } from "apollo-link";
import { DocumentNode } from "graphql";
import { removeUndefined } from "../../types/utils";

import { apollo } from "../api/apollo";
import { periodByEvent, PeriodByEventResult } from "./period.gql";
import { zoneById, ZoneByIdResult } from "./zone.gql";

const client = {
  refresh: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables" | "fetchPolicy">) =>
    apollo.client.query({
      query,
      variables: removeUndefined(variables),
      ...(options || {}),
      fetchPolicy: "network-only",
    }),
  query: (query: DocumentNode, variables: OperationVariables = {}, options?: Omit<QueryOptions<OperationVariables>, "query" | "variables">) =>
    apollo.client.query({ query, variables: removeUndefined(variables), ...(options || {}) }),
  mutate: (mutation: DocumentNode, variables: OperationVariables = {}, options?: Omit<MutationOptions<OperationVariables>, "variables">) =>
    apollo.client.mutate({ mutation, variables: removeUndefined(variables), ...(options || {}) }),
  subscribe: <T>(query: DocumentNode, variables: OperationVariables = {}, options?: Omit<SubscriptionOptions<OperationVariables>, "variables">) =>
    apollo.client.subscribe({ query, variables: removeUndefined(variables), ...(options || {}) }),
};

type Query<T> = ApolloQueryResult<T>;
type Mutation<T> = FetchResult<Partial<T>>;

export namespace gql {
  export const Zone = {
    get: (zoneId: ID): Promise<Query<ZoneByIdResult>> => client.query(zoneById, { zoneId }),
  };
  export const Period = {
    byEvent: (eventId: ID, date: number): Promise<Query<PeriodByEventResult>> => client.query(periodByEvent, { eventId, date }),
  };
}
